<template>
  <div class="prevent-trigger-update">
    <buffer-component
      v-if="copied.length && copied[0] !== undefined"
      :copied="copied"
      :max-width="'100%'"
      :rounded="false"
      class="my-24"
    />
    <v-sheet
      v-if="visible"
      ref="statistics-tnved"
      class="px-4 py-2 mb-4 tnved-box"
    >
      <v-treeview
        ref="tnved"
        v-model="copied"
        :items="tnved"
        item-key="id"
        class="piz123"
        open-all
        open-on-click
        selection-type="independent"
        selectable
        selected-color="black"
        return-object
      >
        <template
          #prepend="{ item }"
        >
          <div class="black-txt d-flex justify-start align-flex-start">
            <div class="txt-box">
              <div class="code-txt mr-5">
                <div
                  v-if="item.code === ''"
                  class="green-minus"
                />
                <span v-else>{{ item.code }}</span>
              </div>
              <div class="light-txt mt-1">
                {{ item.description }}
              </div>
            </div>
          </div>
        </template>
      </v-treeview>
    </v-sheet>
  </div>
</template>
<script>
import BufferComponent from "./buffer.vue";

export default {
  components: {
    BufferComponent,
  },
  props: {
    code: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    procedure: 'IMPORT',
    copied: [],
    tnved: [],
    items: [],
    codePage: {},
    visible: false,
  }),
  watch: {
    code: {
      handler() {
        this.loadData();
      },
      immediate: true,
      deep: true,
    },
    codePage: {
      handler() {
        this.unset();
        if (!Object.keys(this.tnved).length) {
          this.tnved.push(this.codePage.tnVed);
        }
        const ids = this.parseObjectKeys(this.tnved);
        let lastOpen = ids.find((item) => {
          return item.code === this.id;
        });
        if (!Object.keys(this.copied).length) {
          this.copied.push(lastOpen);
        }
      },
      deep: true,
    },
  },
  methods: {
    unset() {
      this.copied = [];
      this.tnved = [];
      this.items = [];
    },
    loadData() {
      if (this.code !== null && this.code.trim().length) {
        this.visible = false;
        this.$store
          .dispatch("tnved/getSingleCode", {
            code: this.code,
            date: this.date,
            procedure: this.procedure,
          })
          .then((res) => {
            if (res.data.commonTarif !== null && res.data.tnVed !== null) {
              this.codePage = res.data;
              this.visible = true;
            }
          });
      }
    },
    parseObjectKeys(obj) {
      const ids = [];
      for (var prop in obj) {
        var sub = obj[prop];
        if (prop == "id") {
          ids.push(obj);
        }
        if (typeof sub == "object") {
          this.parseObjectKeys(sub);
        }
      }
      return ids;
    },
  },
};
</script>
