<template>
  <div>
    <v-tooltip
      max-width="400"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-chip
          label
          v-bind="attrs"
          class="py-6 d-none d-md-flex d-lg-flex"
          :class="{ 'rounded-0': !rounded }"
          v-on="on"
          @click="copyUrl"
        >
          <div
            style="overflow: hidden"
            :style="{ 'max-width': maxWidth }"
          >
            <span
              v-for="(item, index) in items"
              :key="index"
            >
              {{ item }}
              <span v-if="index !== items.length - 1" />
            </span>
          </div>
          <v-icon class="ml-2">
            mdi-checkbox-multiple-blank-outline
          </v-icon>
        </v-chip>
      </template>
      <span
        v-for="(item, index) in items"
        :key="index"
      >
        {{ item }}
        <span v-if="index !== items.length - 1" />
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    copied: {
      type: Array,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "200px",
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    items: [],
  }),
  watch: {
    copied: {
      handler() {
        this.transformItems();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    transformItems() {
      this.items = this.copied.map((item) => {
        return (
          item?.description
            .toUpperCase()
            .replace(/–/g, "")
            .replace(/ {1,}/g, " ") || ""
        );
      });
    },
    copyUrl() {
      let result = this.items
        .join(" ")
        .toUpperCase()
        .replace(/–/g, "")
        .replace(/ {1,}/g, " ");
      navigator.permissions.query({name:'clipboard-write'}).then((res) => {
        if(res.state === "granted" || res.state === "prompt"){
          navigator.clipboard
            .writeText(result)
            .then(() => this.$info("Скопировано в буфер обмена"))
            .catch(() => this.$error("Не удалось скопировать фрагмент"));
        }else{
          this.$error("Недостаточно прав")
        }
      })
    },
  },
};
</script>